import React from 'react'
import logo from '../assest/logo1.jpg'
import '../stylings/header.css'
import { Link } from 'react-router-dom'
import admissionForm from '../assest/admission_form.pdf'
import newGif from '../assest/newGif.gif'

const Header = () => {
    return (
        <div className="headerTop">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 headerContainer">

                        <div className="headerTop_Left">
                            <Link to="/" tabIndex="0">
                                <img src={logo} /></Link>
                        </div>

                        <div className="headerTop_Right">
                            <div className="ContBox-Container">
                                <div className="ContBox">
                                    <div className="ContBoxleft">
                                        <i className="fa-solid fa-location-dot"></i>
                                    </div>
                                    <div className="ContBoxRight">
                                        <Link to="https://maps.app.goo.gl/S9eiJ715eFjEyEvp6" target="_blank" tabIndex="0">
                                            <div className="ContBoxHd">LOCATION</div>
                                            <div className="ContBoxContent">
                                                Click For Map Location
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="ContBox">
                                    <div className="ContBoxleft">
                                        <i className="fa-solid fa-phone-volume"></i>
                                    </div>
                                    <div className="ContBoxRight">
                                        <Link to="tel:+91-9999740333">
                                            <div className="ContBoxHd">CALL US</div>
                                            <div className="ContBoxContent">
                                                9999740333
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="ContBox">
                                    <div className="ContBoxleft">
                                        <i className="fa-regular fa-envelope"></i>
                                    </div>
                                    <div className="ContBoxRight">
                                        <Link to="mailto:vjconvent2004@gmail.com">
                                            <div className="ContBoxHd">EMAIL US</div>
                                            <div className="ContBoxContent">
                                                vjconvent2004@gmail.com
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="btn_top">
                                <Link to="/login" tabIndex="0">myCampus Login</Link>
                                <Link to="/public-disclosure" tabIndex="0">Public Disclosure</Link>

                                <Link to={admissionForm} tabIndex="0" target='_blank' className='admission-form'> Download Admission Form
                                    <div className="img-container">
                                        <img src={newGif} alt="news-new-gif" />
                                    </div>
                                </Link>
                            </div> */}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header

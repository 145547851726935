import React from 'react'
import '../stylings/footer.css'
import phone from "../assest/phone_logo.png"
import playstore from "../assest/playstore.png"
import myCampusFullLogo from "../assest/myCampusFullLogo.png"
import myCampusHat from "../assest/myCampusThumbnailLogo.png"
import { Link } from 'react-router-dom'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';


const Footer = () => {
    return (
        <div className="box-container footer">
            {/* <div className="container">

                <div className="box">
                    <div className="header">
                        Contact Info
                    </div>
                    <div className="content">
                        <p>Vidya Jain Convent School</p>
                        <p>Khasra No.- 142, Plot - 289 , Delhi - 110081</p>
                        <p><Link to="tel:+91-9999740333">Phone:- 9999740333</Link></p>
                        <p><Link to="mailto:vjconvent2004@gmail.com">E-mail:- vjconvent2004@gmail.com</Link></p>
                    </div>
                    <div className="content social-handler">
                        <Link to="https://www.instagram.com/vjconventschool/" target="_blank"><i class="fa-brands fa-instagram"></i></Link>
                        <Link to="https://www.youtube.com/@vjconventschool515" target="_blank"><i class="fa-brands fa-youtube"></i></Link>
                        <Link to="https://www.facebook.com/vjconvent/" target="_blank"><i class="fa-brands fa-facebook"></i></Link>
                        <Link to="https://wa.me/919999740333" target="_blank"><i class="fa-brands fa-whatsapp"></i></Link>
                    </div>
                </div>

                <div className="box">
                    <div className="header">
                        Useful Links
                    </div>
                    <div className="content">
                        <p><Link to="https://scstwelfare.delhi.gov.in/" target='_blank'><i className="fa-solid fa-chevron-right"></i>Government Scholarship-SC/ST/OBC</Link></p>
                        <p><Link to="https://www.minorityaffairs.gov.in/" target='_blank'><i className="fa-solid fa-chevron-right"></i>Government Scholarships-Minority</Link></p>
                        <p><Link to="https://scholarships.gov.in/" target='_blank'><i className="fa-solid fa-chevron-right"></i>National Scholarship</Link></p>
                        <p><Link to="https://www.cbse.gov.in/" target='_blank'><i className="fa-solid fa-chevron-right"></i>Official Website of CBSE</Link></p>
                        <p><Link to="#"><i className="fa-solid fa-chevron-right"></i>School Audit</Link></p>
                        <p><Link to="#"><i className="fa-solid fa-chevron-right"></i>Kids Corner</Link></p>
                    </div>
                </div>

                <div className="box phone">
                    <div className="img-container">
                        <img src={phone} alt="" />
                    </div>
                </div>

                <div className="box last">

                    <div className="text-center social-container">
                        <div className="social">
                            <p>Download <span><img src={myCampusFullLogo} alt="" /></span> App</p>
                            <div className="mt-2">
                                <Link to="https://play.google.com/store/apps/details?id=com.mycampus.smsapp" target="_blank" tabIndex="0">
                                    <img src={playstore} />
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="slider footer-slider">
                        <div className="img-container">
                            <img src={myCampusHat} alt="" />
                        </div>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination]}
                            className="footer-Swiper"
                        >
                            <SwiperSlide>
                                <div className="slider-content">
                                    <p>Transaction and Fee Payments History.</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="slider-content">
                                    <p>Notice and Announcements from School.</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="slider-content">
                                    <p>Instant Push Notification on all activity from school.</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="slider-content">
                                    <p>Apply your leave of absent and instant approval.</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="slider-content">
                                    <p>All your homework and assignments on single click.</p>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="slider-content">
                                    <p>Today's activity of school like Birthday, Events etc.</p>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div> */}

            <div className="container copyright">
                <p>&copy;Copyright 2024, all rights reserved with Vidya Jain Convent School</p>
                <div className="designed-detail">
                    <p>Designed and Developed By: <Link to="http://www.mycampus.co.in/" target="_blank"
                        rel="noopener noreferrer">myCampus Technology</Link></p>
                </div>
            </div>
        </div>
    )
}

export default Footer

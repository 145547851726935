import React from 'react'
import Header from './Header'
import Navbar from './Navbar'
import Footer from './Footer'
import BannerImage from './BannerImage'
import NewsMarquee from './NewsMarquee'

const Layout = ({ children }) => {

  

  return (
    <div>
      {/* <NewsMarquee/> */}
      <Header />
      <Navbar />
      <div className="children-body">
        {children}
      </div>
      <BannerImage />
      <Footer />
    </div>
  )
}

export default Layout
